import { AbstractModel, Column, Index, Model } from "@clairejs/core";

@Model()
@Index([["langCode", 1]], { unique: true })
export class Country extends AbstractModel {
    @Column({
        description: "Name of the country",
        isRequired: true,
        textLength: 64,
        isSymbol: true,
    })
    name!: string;

    @Column({
        description: "Internaltional language code",
        isRequired: true,
        textLength: 4,
        isSymbol: true,
    })
    langCode!: string;

    @Column({ description: "Dominant currency of this country", isRequired: true })
    currencyCode!: string;

    @Column({
        description: "Whether service in this country is diabled",
    })
    disabled?: boolean;

    @Column({
        description: "Language flag image",
        isRequired: true,
    })
    flagUrl!: string;

    @Column({
        description: "Nation flag image",
    })
    nationFlagUrl?: string;

    @Column({
        description: "Display name of the language",
        isRequired: true,
        textLength: 64,
    })
    langName!: string;

    @Column({
        description: "Prefix without +",
        isRequired: true,
        textLength: 5,
    })
    phonePrefix!: string;

    @Column({
        description: "The api server url",
    })
    apiServerUrl?: string;

    @Column({
        description: "Enable language selection in app",
    })
    languageEnabled?: boolean;

    @Column({
        description: "Enable region selection in app",
    })
    regionEnabled?: boolean;

    @Column({
        description: "Enable phone prefix selection in app",
    })
    phoneEnabled?: boolean;
}
