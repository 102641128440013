import { Button } from "antd";

interface Props {
    icon: any;
    disabled?: boolean;
    onClick?: () => void;
}

export default function LoginButton({ icon, disabled, onClick }: Props) {
    return (
        <Button disabled={disabled} onClick={onClick}>
            {icon}
        </Button>
    );
}
