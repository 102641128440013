import { AbstractHttpClient, AbstractStorage, AbstractTokenManager, CrudApi } from "@clairejs/client";
import { GetManyResponseBody, Register } from "@clairejs/core";

import { Payment } from "../../dto/models/payment";
import { ServiceProfile } from "../../dto/models/service-profile";
import { User } from "../../dto/models/user";

import { ConfirmPaymentRequest } from "../../dto/http/payment";
import { BanServiceProfileBody } from "../../dto/http/service";
import {
    CreateSessionBody,
    CreateSessionResponse,
    GetCurrentUserInfoResponse,
    ResetUserPasswordBody,
    UpdateUserLocationBody,
    VerifyAccountBody,
} from "../../dto/http/user";

const USER_BASIC_INFO_KEY = "USER_BASIC_INFO";

@Register()
export class UserApi extends CrudApi<User> {
    constructor(
        private readonly http: AbstractHttpClient,
        private readonly tokenManager: AbstractTokenManager,
        private readonly storage: AbstractStorage,
    ) {
        super(User, http);
    }

    async hasSession() {
        const token = await this.tokenManager.getAccessToken();
        return !!token;
    }

    async deleteAccount() {
        return await this.http.delete("/account");
    }

    async updateLocation(body: UpdateUserLocationBody) {
        return await this.http.post("/user/location", body);
    }

    async fetchFavorites() {
        return await this.http.get<GetManyResponseBody<User>>("/user/favorites");
    }

    async fetchBlocked() {
        return await this.http.get<GetManyResponseBody<User>>("/user/blockeds");
    }

    async setUserInfoToLocal(info?: GetCurrentUserInfoResponse) {
        if (info) {
            await this.storage.setItem(USER_BASIC_INFO_KEY, info);
        } else {
            await this.storage.deleteItem(USER_BASIC_INFO_KEY);
        }
    }

    async getUserInfoFromLocal() {
        return await this.storage.getItem<GetCurrentUserInfoResponse>(USER_BASIC_INFO_KEY);
    }

    async logout() {
        return await Promise.all([this.tokenManager.setAccessToken(), this.setUserInfoToLocal()]);
    }

    async createSession(body: CreateSessionBody) {
        const result = await this.http.post<CreateSessionResponse>("/session", body, {}, { noAuthorization: true });

        if (result?.accessToken) {
            await this.tokenManager.setAccessToken(result.accessToken);
        }

        return result;
    }

    async requestResetPassword(body: ResetUserPasswordBody) {
        await this.http.post("/user/reset/request", body, {}, { noAuthorization: true });
    }

    async performResetPassword(body: VerifyAccountBody) {
        await this.http.post("/user/reset/perform", body, {}, { noAuthorization: true });
    }
}

@Register()
export class PaymentApi extends CrudApi<Payment> {
    constructor(protected readonly http: AbstractHttpClient) {
        super(Payment, http);
    }

    async confirmPayment(body: ConfirmPaymentRequest) {
        return await this.http.post<Payment>(`/payment/confirm`, body);
    }
}

@Register()
export class ServiceProfileApi extends CrudApi<ServiceProfile> {
    constructor(protected readonly http: AbstractHttpClient) {
        super(ServiceProfile, http);
    }

    async banProfile(id: string, body: BanServiceProfileBody) {
        await this.http.post(`/serviceprofile/${id}/ban`, body);
    }
}
