import { Data, Field, FK } from "@clairejs/core";

import { User } from "./user";

@Data()
export class VendorReply {
    @Field({
        description: "Id of vendor user who reply",
        isRequired: true,
        ...FK({ model: User }),
    })
    vendor!: string;

    @Field({
        isTimestamp: true,
        isRequired: true,
    })
    repliedAt!: number;

    @Field({
        description: "Price that vendor bid, leave empty to reject",
    })
    priceBid?: string;

    @Field({
        description: "Number of coin cost for this bid",
    })
    bidCost?: number;

    @Field({
        description: "Note of vendor for user",
    })
    vendorNote?: string;
}
