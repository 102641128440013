import { AbstractModel, Column, DataType, Model, Enum, FK } from "@clairejs/core";

import { Country } from "./country";

export enum PaymentAccountType {
    BANK = "bank",
    STRIPE = "stripe",
}

export interface BankAccountInfo {
    bankName: string;
    bankAccount: string;
    accountHolder: string;
}

export interface StripeAccountInfo {
    publishableKey: string;
}

export type PaymentAccountInfo = BankAccountInfo | StripeAccountInfo;

export interface NetworkInfo {
    chainName: string;
    rpcUrls: string[];
    nativeCurrency: string;
    blockExplorerUrls: string[];
    confirmations: number;
    blocktimeSec: number;
}

@Model()
export class PaymentAccount extends AbstractModel {
    @Column({
        description: "Currency codes that this account supports",
        isRequired: true,
        vectorProps: { elementDataType: DataType.STRING },
    })
    currencies!: string[];

    @Column({
        description: "Ids of countries that this account operates",
        isRequired: true,
        vectorProps: { elementDataType: DataType.STRING },
        ...FK({ model: Country }),
    })
    countryIds!: string[];

    @Column({
        description: "Whether this account is disabled",
    })
    disabled: boolean = false;

    @Column({
        description: "Type of payment account",
        isRequired: true,
        ...Enum(PaymentAccountType),
    })
    type!: PaymentAccountType;

    @Column({
        isRequired: true,
    })
    info!: PaymentAccountInfo;
}
