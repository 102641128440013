import { Constructor, getServiceProvider } from "@clairejs/core";
import { Tlt, getText } from "@clairejs/react";
import { useCallback, useRef } from "react";

import { ErrorHandler, getErrorTranslation } from "../utils/error-handler";

export const useInject = <T,>(cls: Constructor<T>) => {
    const instance = useRef(getServiceProvider().getInjector().resolve(cls));
    return instance.current as T;
};

export const useErrorHandler = () => {
    const handler = useInject(ErrorHandler);

    const handle = useCallback(
        async (err: any) => {
            try {
                const { name, message } = getErrorTranslation(err);

                if (name || message) {
                    await handler.handle({
                        name: name && getText(name),
                        message: message && getText(message),
                    });
                }
            } catch (newErr: any) {
                const { name, message } = {
                    name: <Tlt exp={newErr.name} />,
                    message: newErr.message ? <Tlt exp={newErr.message} /> : <Tlt id="error_unknown" />,
                };

                await handler.handle({
                    name: getText(name),
                    message: message && getText(message),
                });
            }
        },
        [handler],
    );

    return handle;
};
