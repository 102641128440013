import { AbstractModel, Column, DataType, Index, Mime, Model, Enum, FK, Data, Field, GeoPoint } from "@clairejs/core";

export enum Gender {
    MALE = "male",
    FEMALE = "female",
}

@Data()
export class GeoPlace extends GeoPoint {
    @Field({ isRequired: true })
    name!: string;
}

const phoneNumberRegex = "^0\\d{8,16}$";

@Model()
@Index([["temporaryBlocked", 1]])
@Index([["userId", 1]], { unique: true })
@Index([["email", 1]], { unique: true, sparse: true })
export class User extends AbstractModel {
    @Column({
        description: "Principal id of user obtained from id sdk",
        isRequired: true,
    })
    userId!: string;

    @Column({
        description: "User-facing id",
    })
    readableId?: number;

    @Column({
        description: "Avatar of user",
        mimeProps: { type: Mime.IMAGE, public: true },
        textLength: 512,
    })
    avatar?: string;

    @Column({
        description: "Display name of user",
        textLength: 128,
        allowNull: true,
    })
    displayName?: string;

    @Column({
        description: "Unique phone number of user",
        regex: phoneNumberRegex,
        textLength: 16,
    })
    phoneNumber?: string;

    @Column({
        description: "Phone number prefix",
        textLength: 5,
    })
    phoneNumberPrefix?: string;

    @Column({
        description: "Email of user",
    })
    email?: string;

    @Column({
        description: "Whether the email is verified",
    })
    emailVerified?: boolean;

    @Column({
        description: "Ids of services that this user provide, this is used to speedup search query",
        vectorProps: { elementDataType: DataType.STRING },
    })
    serviceIds?: string[];

    @Column({
        description: "Whether user is temporary off",
    })
    off?: boolean;

    @Column({
        description: "Whether this user is disabled by system",
    })
    disabled?: boolean;

    @Column({
        description: "The reason for being disabled",
    })
    disableReason?: string;

    @Column({
        description: "Whether user is using vendor mode",
    })
    vendorMode?: boolean;

    @Column({
        description: "Account balance of user",
        isRequired: true,
        serverValue: true,
        rangeProps: { min: 0 },
    })
    accountBalance: number = 0;

    @Column({
        isRequired: true,
        description: "Id of favorite vendors",
        vectorProps: { elementDataType: DataType.STRING },
    })
    favoriteVendors: string[] = [];

    @Column({
        isRequired: true,
        description: "Id of users being blocked by this user",
        vectorProps: { elementDataType: DataType.STRING },
    })
    blocked: string[] = [];

    @Column({})
    temporaryBlocked?: Record<string, string>;

    @Column({
        description: "Address of user for service",
        allowNull: true,
        textLength: 256,
    })
    address?: string;

    @Column({
        description: "Geo location of user",
        allowNull: true,
    })
    location?: GeoPlace;

    @Column({
        description: "Auto update location",
    })
    autoLocation?: boolean;

    @Column({
        isTimestamp: true,
    })
    locationLastUpdate?: number;

    @Column({
        description: "Birth year info of user",
        allowNull: true,
    })
    birthYear?: number;

    @Column({
        description: "Gender of user",
        ...Enum(Gender),
    })
    gender?: Gender;

    @Column({
        description: "Selected region",
    })
    selectedRegion?: string;

    @Column({
        description: "Selected locale",
    })
    selectedLocale?: string;

    @Column({
        description: "Total number of service profile",
        isRequired: true,
    })
    profileCount: number = 0;

    @Column({ description: "Firebase push notification device token" })
    deviceToken?: string;

    @Column({
        description: "Timezone offset in minutes against UTC",
    })
    timeZone?: number;

    @Column({
        description: "Whether the user had been guided through the app",
    })
    onboarded?: boolean;

    @Column({
        description: "Additional info of user",
    })
    moreInfo?: string;

    @Column({
        description: "This user has limited access to the app",
    })
    limited?: boolean;
}

export const getUserDisplay = (user?: User, empty: string = "") => {
    return user ? user.displayName || user.phoneNumber || user.id : empty;
};
