import { AbstractModel, Column, DataType, Index, Mime, Model } from "@clairejs/core";

@Model()
@Index([["name", "text"]])
export class Service extends AbstractModel {
    @Column({
        description: "Service name",
        isRequired: true,
    })
    name!: string;

    @Column({
        isRequired: true,
        description: "Service description",
    })
    description!: string;

    nameLocale?: Record<string, string>;

    descriptionLocale?: Record<string, string>;

    @Column({
        description: "The countries that this service is available",
        vectorProps: { elementDataType: DataType.STRING },
    })
    countries?: string[];

    @Column({
        mimeProps: { type: Mime.IMAGE },
    })
    iconUrl?: string;

    @Column({
        description: "Number of registered profiles on this service per region",
    })
    profileCount?: number;

    @Column({
        description: "Number of searches perform on this service per region",
    })
    searchCount?: Record<string, number>;
}
