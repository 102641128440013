import {
    AbstractModel,
    Column,
    Model,
    FK,
    Index,
    ElementDto,
    Identifiable,
    Data,
    Field,
    Mime,
    DataType,
} from "@clairejs/core";

import { Service } from "./service";
import { User, GeoPlace } from "./user";

@Data()
export class ServiceProfileImage extends Identifiable {
    @Field({ isRequired: true, mimeProps: { public: true, type: Mime.IMAGE, maxFileKB: 10240 } })
    image!: string;
}

@Model()
@Index(
    [
        ["userId", 1],
        ["serviceId", 1],
    ],
    { unique: true },
)
@Index([["location", "2dsphere"]])
export class ServiceProfile extends AbstractModel {
    @Column({
        isRequired: true,
        ...FK({ model: Service, cascade: "delete" }),
    })
    serviceId!: string;

    @Column({
        description: "Id of user who is vendor",
        isRequired: true,
        serverValue: true,
        ...FK({ model: User, cascade: "delete" }),
    })
    userId!: string;

    @Column({
        textLength: 1000,
    })
    profileDescription?: string;

    @Column({
        description: "Whether the service is temporary off",
    })
    off?: boolean;

    @Column({
        description: "Whether the service is removed by user",
        serverValue: true,
    })
    removed?: boolean;

    @Column({
        isRequired: true,
        description: "The location where user will find your service",
    })
    location!: GeoPlace;

    @Column({
        description: "Whether the profile is disabled by system",
        serverValue: true,
    })
    disabled?: boolean;

    @Column({
        description: "The reason of disabled",
        serverValue: true,
    })
    disableReason?: string;

    @Column({
        description: "Total number of service done",
        serverValue: true,
    })
    serviceCount: number = 0;

    @Column({
        description: "Total number of service cancelled",
        serverValue: true,
    })
    cancelCount: number = 0;

    @Column({
        description: "Total rating count",
        isRequired: true,
        serverValue: true,
    })
    rateTotal: number = 0;

    @Column({
        description: "Rating average",
        serverValue: true,
        floating: true,
    })
    rating?: number;

    @Column({
        vectorProps: { elementDataType: DataType.OBJECT },
        ...ElementDto(ServiceProfileImage),
    })
    images?: ServiceProfileImage[];
}
