import { useLanguage } from "@clairejs/react";
import { Radio } from "antd";
import React, { useEffect } from "react";

const LANG_KEY = "LANGUAGE";

function LangSwitcher() {
    const [currentLang, setCurrentLang] = useLanguage();

    const updateLang = (lang: string) => {
        setCurrentLang(lang);
        localStorage.setItem(LANG_KEY, lang);
    };

    useEffect(() => {
        if (!currentLang) {
            const lang = localStorage.getItem(LANG_KEY);
            setCurrentLang(lang || "vi");
        }
    }, [currentLang]);

    return (
        <Radio.Group size="small" value={currentLang} onChange={(e) => updateLang(e.target.value)}>
            <Radio.Button value="vi">VI</Radio.Button>
            <Radio.Button value="en">EN</Radio.Button>
        </Radio.Group>
    );
}

export default React.memo(LangSwitcher);
