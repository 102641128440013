import { AccessToken, Data, DataType, Field, GetManyQueriesClass, pick, select, Enum } from "@clairejs/core";

import { User } from "../models/user";
import { VendorInfo } from "../models/search";

export enum IdentityProvider {
    FACEBOOK = "FACEBOOK",
    APPLE = "APPLE",
    EMAIL = "EMAIL",
}

@Data()
export class BasicUserInfo extends pick(User, ["id", "displayName", "avatar"]) {}

@Data()
export class BasicVendorInfo extends select(
    pick(User, ["id", "displayName", "avatar", "birthYear", "address", "gender"]),
)
    .combine(pick(VendorInfo, ["profileId", "vendorId", "distance", "rating"]))
    .get() {}

@Data()
export class ContactUserInfo extends pick(User, ["id", "phoneNumber"]) {}

@Data()
export class CreateSessionBody {
    @Field({
        ...Enum(IdentityProvider),
        isRequired: true,
    })
    provider!: IdentityProvider;

    @Field({
        isRequired: true,
        vectorProps: { elementDataType: DataType.STRING },
    })
    data!: string[];
}

@Data()
export class VerifyOtpBody extends pick(User, ["phoneNumber", "phoneNumberPrefix"]) {}

@Data()
export class CreateTfaSessionBody {
    @Field({
        description: "The user tfa code",
        isRequired: true,
    })
    code!: string;
}

@Data()
export class RefreshSessionBody {
    @Field({
        description: "The issued refresh token",
        isRequired: true,
    })
    refreshToken!: string;
}

@Data()
export class CreateSessionResponse {
    @Field({
        description: "Access token of user",
        isRequired: true,
    })
    accessToken?: AccessToken;

    @Field({
        description: "Whether the inputed credentials is not valid",
    })
    invalidCredential?: boolean;

    @Field({
        description: "Whether the user must perform verification",
    })
    mustVerify?: boolean;
}

@Data()
export class ResetUserPasswordBody extends pick(User, ["email"]) {
    @Field({
        description: "New password",
        isRequired: true,
    })
    secret!: string;
}

@Data()
export class VerifyAccountBody extends ResetUserPasswordBody {
    @Field({
        description: "The verification OTP",
        isRequired: true,
    })
    otp!: string;
}

@Data()
export class UpdateUserLocationBody extends pick(User, ["location"]) {}

//-- info
@Data()
export class UserInfo extends pick(User, [
    "id",
    "selectedLocale",
    "selectedRegion",
    "phoneNumber",
    "location",
    "accountBalance",
    "vendorMode",
    "off",
    "avatar",
    "displayName",
    "gender",
    "birthYear",
    "address",
    "favoriteVendors",
    "blocked",
    "deviceToken",
    "profileCount",
    "autoLocation",
    "locationLastUpdate",
    "onboarded",
    "limited",
]) {}

@Data()
export class GetCurrentUserInfoQueries extends GetManyQueriesClass(User) {
    @Field({
        description: "Current app version",
    })
    appVersion?: string;
}

@Data()
export class GetCurrentUserInfoResponse {
    @Field({
        description: "User info",
        isRequired: true,
    })
    user!: UserInfo;
}

@Data()
export class FavoriteVendorBody {
    @Field({ description: "True to add, false to remove", isRequired: true })
    add!: boolean;

    @Field({
        isRequired: true,
        vectorProps: { elementDataType: DataType.STRING },
        description: "Ids of users to add / remove",
    })
    ids!: string[];
}

@Data()
export class CreateAdminSessionBody {
    @Field({})
    username!: string;

    @Field({})
    password!: string;
}

@Data()
export class BanUserBody extends pick(User, ["disabled", "disableReason"]) {}
