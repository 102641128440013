import { AccessToken } from "@clairejs/core";
import { Tlt, useAPI } from "@clairejs/react";
import { useNavigator } from "@clairejs/react-web";
import { CCard, CCardBody, CCardGroup, CCol, CContainer, CRow } from "@coreui/react";
import { Button, Form, Input } from "antd";
import { useState } from "react";

import { CreateSessionResponse, IdentityProvider } from "../../dto/http/user";

import { UserApi } from "../../store/api/user";

import logo from "../../assets/logo.svg";

import { useErrorHandler } from "../../hooks/error";

import FacebookLoginButton from "./facebook-login";
import LangSwitcher from "../common/lang-switcher";

const Login = () => {
    const errorHandler = useErrorHandler();
    const api = useAPI(UserApi);
    const navigator = useNavigator();

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);

    const disabled = !username || !password;

    const handleSession = async (response?: CreateSessionResponse) => {
        if (response?.accessToken) {
            //--- to home screen
            navigator.navigate("/home");
        }
    };

    const login = async (provider: IdentityProvider, data: string[]) => {
        try {
            setLoading(true);

            const response = await api.createSession({
                provider,
                data,
            });

            await handleSession(response);
        } catch (err: any) {
            errorHandler(err);
        } finally {
            setLoading(false);
        }
    };

    const loginWithPassword = async () => {
        await login(IdentityProvider.EMAIL, [username, password]);
    };

    const handleSocialLogin = (provider: IdentityProvider) => (err: any, result?: AccessToken) => {
        if (err || !result) {
            errorHandler(err);
        } else {
            login(provider, [result.token]);
        }
    };

    return (
        <div className="bg-body-tertiary min-vh-100 d-flex flex-row align-items-center">
            <CContainer>
                <CRow className="justify-content-center">
                    <CCol md={7}>
                        <CCardGroup>
                            <CCard className="text-white bg-primary">
                                <CCardBody className="text-center">
                                    <div className="d-none d-sm-block" style={{ flex: 1 }}>
                                        <img
                                            alt="logo"
                                            src={logo}
                                            style={{
                                                objectFit: "contain",
                                                maxHeight: 233,
                                                width: "100%",
                                                height: "100%",
                                            }}
                                        />
                                    </div>
                                </CCardBody>
                            </CCard>
                            <CCard className="p-4">
                                <CCardBody>
                                    <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: 13 }}>
                                        <LangSwitcher />
                                    </div>
                                    <h4 style={{ marginBottom: 21 }}>
                                        <Tlt template="Admin Portal" />
                                    </h4>
                                    <Form>
                                        <Form.Item>
                                            <Input
                                                disabled={loading}
                                                className="custom-input"
                                                placeholder="Username"
                                                onChange={(e) => setUsername(e.target.value)}
                                            />
                                        </Form.Item>
                                        <Form.Item>
                                            <Input.Password
                                                disabled={loading}
                                                className="custom-input"
                                                placeholder="Password"
                                                onChange={(e) => setPassword(e.target.value)}
                                                onPressEnter={loginWithPassword}
                                            />
                                        </Form.Item>
                                    </Form>
                                    <CRow style={{ display: "flex", alignItems: "center" }}>
                                        <CCol xs={6} className="text-right">
                                            <div style={{ borderWidth: 1, borderColor: "red" }}>
                                                <FacebookLoginButton
                                                    disabled={loading}
                                                    onLogin={handleSocialLogin(IdentityProvider.FACEBOOK)}
                                                />
                                            </div>
                                        </CCol>
                                        <CCol xs={6} style={{ display: "flex" }}>
                                            <Button
                                                onClick={loginWithPassword}
                                                loading={loading}
                                                disabled={disabled}
                                                className="custom-button"
                                                style={{ width: "100%" }}
                                                type="primary">
                                                <Tlt template="Log in" />
                                            </Button>
                                        </CCol>
                                    </CRow>
                                </CCardBody>
                            </CCard>
                        </CCardGroup>
                    </CCol>
                </CRow>
            </CContainer>
        </div>
    );
};

export default Login;
