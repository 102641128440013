import { cibFacebook } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { useEffect } from "react";
import { AccessToken } from "@clairejs/core";

import LoginButton from "./login-button";

interface Props {
    onLogin: (err: any, result?: AccessToken) => void;
    disabled?: boolean;
    loading?: boolean;
}

const w = window as any;
export default function FacebookLoginButton({ onLogin, ...props }: Props) {
    useEffect(() => {
        w.fbAsyncInit = function () {
            w.FB.init({
                appId: "1073426817038226",
                xfbml: true,
                version: "v22.0",
            });
            w.FB.AppEvents.logPageView();
        };

        (function (d, s, id) {
            var js,
                fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {
                return;
            }
            js = d.createElement(s);
            js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode?.insertBefore(js, fjs);
        })(document, "script", "facebook-jssdk");
    }, []);

    const login = async () => {
        w.FB.login(
            (response) => {
                if (response.status !== "connected") {
                    return onLogin(null, undefined);
                }

                return onLogin(null, {
                    token: response.authResponse.accessToken,
                    expiration: Date.now() + response.authResponse.expiresIn * 1000,
                });
            },
            { scope: "public_profile,email" },
        );
    };

    return <LoginButton {...props} onClick={login} icon={<CIcon icon={cibFacebook} />} />;
}
