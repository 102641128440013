import { Register } from "@clairejs/core";
import { AbstractErrorHandler, Translator } from "@clairejs/client";
import { Tlt } from "@clairejs/react";

export const getErrorTranslation = (error: any) => {
    //-- suppress some of the errors
    if (["TOKEN_EXPIRED", "CLIENT_TOO_OLD"].includes(error.name)) {
        return {};
    }

    if (error.name === "PRICE_UPDATED") {
        return {
            name: <Tlt id="error_PRICE_UPDATED" />,
            message: <Tlt id="error_PRICE_UPDATED_explain" />,
        };
    }

    if (error.name === "DISPOSABLE_EMAIL") {
        return {
            message: <Tlt id="error_DISPOABLE_EMAIL_explain" />,
        };
    }

    if (error.name === "SUGGEST_TOO_MUCH") {
        return {
            name: <Tlt id="error_SUGGEST_TOO_MUCH" />,
        };
    }

    if (error.name === "DIRTY_LANGUAGE") {
        return {
            name: <Tlt id="error_DIRTY_LANGUAGE" />,
        };
    }

    if (error.name === "REQUEST_RATE_LIMIT") {
        return {
            name: <Tlt id="error_REQUEST_RATE_LIMIT" />,
            message: <Tlt id="error_REQUEST_RATE_LIMIT_explain" />,
        };
    }

    if (error.name === "BAD_STATE") {
        if (error.message === "Incorrect code") {
            return {
                name: <Tlt id="error_VERIFICATION_INCORRECT" />,
            };
        }

        if (error.message === "Cannot bid this search") {
            return {
                name: <Tlt id="error_BID_SEARCH" />,
                message: <Tlt id="error_BID_SEARCH_explain" />,
            };
        }

        if (error.message === "Cannot accept this bid") {
            return {
                name: <Tlt id="error_ACCEPT_SEARCH" />,
                message: <Tlt id="error_ACCEPT_SEARCH_explain" />,
            };
        }

        if (error.message === "Maximum verification exceeded") {
            return {
                name: <Tlt id="error_VERIFICATION_EXCEEDED" />,
                message: <Tlt id="error_VERIFICATION_EXCEEDED_explain" />,
            };
        }
    }

    if (error.name === "HTTP_REQUEST_ERROR") {
        return {
            name: <Tlt id="error_HTTP" />,
            message: <Tlt id="error_HTTP_explain" />,
        };
    }

    throw error;
};

@Register()
export class ErrorHandler extends AbstractErrorHandler {
    constructor(protected readonly translator: Translator) {
        super();
    }

    async handle(err: any) {
        const error = err.response ? err.response.data : err;

        const { name, message } = error;

        alert(message || name);
    }
}
