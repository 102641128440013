import { AbstractModel, Column, Model, FK, Index, HttpMethod, Enum } from "@clairejs/core";

import { User } from "./user";
import { PaymentAccount } from "./payment-account";

export enum PaymentState {
    CREATED = "created",
    INITED = "inited",
    SUCCESS = "success",
    FAILED = "failed",
}

export enum ServiceType {
    COIN = "coin",
}

export interface CoinPaymentData {
    coinAmount: number;
}

export interface BankTransferData {}

export interface StripeTransferData {}

export interface BlockchainTransferData {
    network?: number;
    signature?: string;
    txHash?: string;
}

interface TransferMetadata {
    attemptConfirm?: boolean;
}

export interface BankMetadata extends TransferMetadata {
    userId?: string;
    state?: PaymentState;
}

export interface StripeMetadata extends TransferMetadata {
    jobId?: string;
    returnURL: string;
    intentId: string;
    clientSecret: string;
}

export interface BlockchainMetadata extends TransferMetadata {
    jobId?: string;
    messageToSign?: string;
    account?: string;
    lastBlock?: number;
    senderAddress?: string;
}

@Model({ ignoreCrud: [HttpMethod.POST, HttpMethod.PUT, HttpMethod.DEL] })
@Index([["userId", 1]])
@Index([["state", 1]])
@Index([["uniqueRefId", 1]], { unique: true })
@Index([["createdAt", 1]])
export class Payment extends AbstractModel {
    @Column({
        description: "Id of user who is affected by the transaction",
        isRequired: true,
        ...FK({ model: User }),
    })
    userId!: string;

    @Column({
        description: "State of the payment",
        isRequired: true,
        ...Enum(PaymentState),
    })
    state!: PaymentState;

    @Column({
        description: "The payment account this payment goes to",
        isRequired: true,
        ...FK({ model: PaymentAccount }),
    })
    account!: string;

    @Column({
        description: "Amount of payment",
        isRequired: true,
    })
    amount!: string;

    @Column({
        description: "Currency of payment",
        isRequired: true,
    })
    currency!: string;

    @Column({
        description: "Unique ref id for transfer",
        isRequired: true,
    })
    uniqueRefId!: string;

    @Column({
        description: "Type of service that create this payment",
        isRequired: true,
        ...Enum(ServiceType),
    })
    serviceType!: ServiceType;

    @Column({
        description: "Data for post processing",
        isRequired: true,
    })
    serviceData!: CoinPaymentData;

    @Column({
        description: "Data submitted from client",
    })
    paymentClientData?: BankTransferData | BlockchainTransferData | StripeTransferData;

    @Column({
        description: "Data generated from server",
        serverValue: true,
    })
    paymentServerData?: BlockchainMetadata | BankMetadata | StripeMetadata;

    @Column({
        description: "Failure reason of the payment",
    })
    failureReason?: string;
}
